import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import LittleExpCourses from "../assets/LittleExpCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const LittleExplorersPage = () => {
  const [show2, setShow2] = useState(false); // Используем хук useState для управления состоянием
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Функция для отправки email
  const sendEmail2 = (formData) => {
    handleClose2();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_h2ejs19",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail13 = (formData) => {
    handleClose2();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_72j6gtf",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  // Обработчик отправки формы
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");

    // Определяем куда отправлять данные в зависимости от города
    if (city === "Київ") {
      sendEmail2(formData);
    } else if (city === "Рівне") {
      sendEmail13(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText" alt="Logo" />&nbsp;
            <p className="nameCoursePage">
              Курс «Маленькі дослідники» 3-4 роки
            </p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={LittleExpCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Діти з раннього віку цікавляться навколишнім середовищем, а в 3
                роки активно досліджують його через органи чуття та уяву. Вони
                починають помічати деталі, порівнювати об’єкти та швидко
                засвоюють нову інформацію. Завдання батьків – спрямувати цю
                цікавість у правильному напрямку. Курс «Маленькі дослідники»
                допоможе у цьому!<br/> 
                Під час занять з LEGO Duplo дитина навчиться
                розрізняти деталі за кольором, формою і розміром, рахувати та
                формулювати думки про світ. Також, працюючи в групі, вона
                робитиме перші кроки до ефективної комунікації.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина розвине навички дрібної моторики,
              креативності та комунікабельності. Навчиться розрізняти кольори,
              та форми. Навчиться рахувати та додатково потренує свою пам’ять.
              Чітко формулюватиме свою думку та закріпить такі поняття як
              «однаковий», «різний» та «моє», «чуже».
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 7 дітей.
              <br />
              &#9679; Тривалість заняття: 50 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <Button variant="primary" className="mb-3" onClick={handleShow2}>
              Записатися
            </Button>
            <Modal
              show={show2}
              onHide={handleClose2}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Маленькі дослідники» 3-4 роки
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit1}>
                  <Form.Group controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                    <textarea
                      className="textAreaForm"
                      name="name"
                      required
                      title="Будь ласка, введіть ім'я та прізвище"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                    <Form.Control
                      className="textAreaForm"
                      type="email"
                      name="email"
                      required
                      plaintext
                      title="Будь ласка, введіть електронну адресу"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                    <Form.Control
                      className="textAreaForm"
                      type="tel"
                      name="phone"
                      pattern="[0-9]*"
                      required
                      plaintext
                      title="Будь ласка, введіть номер телефону"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                    <Form.Control
                      className="textAreaForm"
                      name="age"
                      pattern="[0-9]*"
                      required
                      plaintext
                      title="Будь ласка, введіть вік дитини"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCity">
                    <Form.Label className="textModal mt-1">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default LittleExplorersPage;
