import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import FundOfProgCourses from "../assets/FundOfProgCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const FundamentalsOfProgrammingPage = () => {
  const [show5, setShow5] = useState(false);
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // Функция для отправки email
  const sendEmail5 = (formData) => {
    handleClose5();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_bq9as2v",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail17 = (formData) => {
    handleClose5();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_88f0tny",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  // Обработчик отправки формы
  const handleSubmit5 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail5(formData);
    } else if (city === "Рівне") {
      sendEmail17(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCoursePage">
              Курс «Основи програмування» 2-3 клас
            </p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={FundOfProgCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Ера технологій та гаджетів забирає багато часу у дітей, які
                часто грають в ігри на телефоні чи комп’ютері. Але це можна
                перетворити на навчання, залучивши дитину до світу кодів і
                віртуальних розробок. Програмування — це не просто навичка
                майбутнього, а важлива компетенція, яка вже сьогодні розвиває
                дитину в різних напрямках. Опанування програмування відкриває
                безліч можливостей для розвитку особистості та професійних
                навичок. Щоб дитина не втратила інтерес, варто почати з курсу
                «Основи програмування». <br/>
                Він підходить як для новачків, так і для тих, хто хоче поглибити знання після курсу «Вправні
                програмісти». Працюючи на платформі Scratch 3, діти створюють
                мініігри та анімації, вивчаючи складніші поняття програмування у
                легкій формі.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу діти засвоять основні поняття та принципи
              програмування, такі як змінні, цикли, умови, події, функції.
              Набуваючи практичних навичок програмування діти будуть розвивати
              логічне мислення, увагу та пам’ять. Дитина отримає задоволення від
              творчого процесу програмування і побачить свої результати в дії.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 40 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow5}
            >
              Записатися
            </button>
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Основи програмування» 2-3 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit5}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default FundamentalsOfProgrammingPage;
