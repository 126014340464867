import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import WebCourses from "../assets/WebCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const WebProgrammingPage = () => {
  const [show10, setShow10] = useState(false);
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // Функция для отправки email
  const sendEmail10 = (formData) => {
    handleClose10();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_8xvujef",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail22 = (formData) => {
    handleClose10();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_dmc6nls",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  const handleSubmit10 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail10(formData);
    } else if (city === "Рівне") {
      sendEmail22(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCoursePage">Курс «WEB програмування» 5-9 клас</p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={WebCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Веброзробка — це процес створення вебсайтів. Вона має критичне
                значення в сучасному світі, оскільки забезпечує доступ до
                інформації, підвищує ефективність бізнесу, стимулює інновації та
                робить інтернет доступним для всіх. Без веброзробки сучасний
                інтернет, яким майже кожен користується щодня, просто не існував
                би. Це не просто професія, а спосіб робити світ більш
                пов’язаним, зручним і відкритим для нових можливостей.<br/>
                 Курс “WEB програмування” надає можливість учням не тільки зануритися в
                сучасний світ технологій веброзробки, а й отримати фундамент для
                подальшого розвитку в цій сфері. За допомогою практичних завдань
                та проєктів, учні здобудуть необхідні навички програмування та
                створення власних вебсайтів.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу учень ціннісні навички програмування, які
              стануть їй в пригоді в навчанні, роботі та житті, розвине творчий
              потенціал, фантазію та уяву, а також навчиться аналізувати
              інформацію, структурувати дані та створювати алгоритми. Учень
              освоїть технології, що використовуються у провідних IT-компаніях
              світу, підвищить самооцінку, отримає мотивацію та впевненість у
              своїх силах, навчиться демонструвати свої досягнення, знайде нових
              друзів та однодумців працюючи в команді та обмінюючись досвідом.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 2 год.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow10}
            >
              Записатися
            </button>
            <Modal
              show={show10}
              onHide={handleClose10}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «WEB програмування» 5-9 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit10}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose10}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default WebProgrammingPage;
