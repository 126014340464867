import React, { useRef, useState, Component } from "react";
import emailjs from "emailjs-com";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/Courses.css";
import MindStormCourses from "../assets/MindStormCourses.jpg";
import WedoCourses from "../assets/WedoCourses.jpg";
import LittleExpCourses from "../assets/LittleExpCourses.jpg";
import PrepairSchoolCourses from "../assets/PrepairSchoolCourses.jpg";
import ProgrammersCourses from "../assets/ProgrammersCourses.jpg";
import MathematicsProgCourses from "../assets/MathematicsProgCourses.jpg";
import FundOfProgCourses from "../assets/FundOfProgCourses.jpg";
import MinecraftCourses from "../assets/MinecraftCourses.jpg";
import RobloxCourses from "../assets/RobloxCourses.jpg";
import WebCourses from "../assets/WebCourses.jpg";
import GroupCourses from "../assets/GroupCourses.avif";
import NMTCourses from "../assets/NMTCourses.jpg";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import ButtonOnline from "../Components/ButtonOnline";
import LegoLearn from "../assets/LegoLearn.jpg";
import Mechanic from "../assets/Mechanic.jpg";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LittleExplorersPage from "../PagesCourses/LittleExploresPage"; // Импортируем компонент страницы

const Courses = () => {
  const form = useRef();
  const sendEmail1 = (formData) => {
    handleClose1();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_c1jbi8o",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail2 = (formData) => {
    handleClose2();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_h2ejs19",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail3 = (formData) => {
    handleClose3();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_t5c73p7",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail4 = (formData) => {
    handleClose4();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_jn8pqkd",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail5 = (formData) => {
    handleClose5();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_bq9as2v",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail6 = (formData) => {
    handleClose6();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_smemd6s",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail7 = (formData) => {
    handleClose7();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_vxri8aa",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail8 = (formData) => {
    handleClose8();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_moy0i7j",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail9 = (formData) => {
    handleClose9();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_l9gffbk",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail10 = (formData) => {
    handleClose10();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_8xvujef",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail11 = (formData) => {
    handleClose11();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_z7admgi",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail12 = (formData) => {
    handleClose12();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_abmvww9",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail13 = (formData) => {
    handleClose2();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_72j6gtf",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail14 = (formData) => {
    handleClose1();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_mv2g1mr",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail15 = (formData) => {
    handleClose3();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_bpouqzn",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail16 = (formData) => {
    handleClose4();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_lmcfof7",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail17 = (formData) => {
    handleClose5();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_88f0tny",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail18 = (formData) => {
    handleClose6();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_dx6hg3n",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail19 = (formData) => {
    handleClose7();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_8s8nyab",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail20 = (formData) => {
    handleClose8();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_gu3jxbx",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail21 = (formData) => {
    handleClose9();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_9sxymvh",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail22 = (formData) => {
    handleClose10();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_dmc6nls",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail23 = (formData) => {
    handleClose11();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_ezbpn9k",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail24 = (formData) => {
    handleClose12();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_xove8em",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail25 = (formData) => {
    handleClose13();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_z8sobj8",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail26 = (formData) => {
    handleClose13();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_gy3mmrb",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail27 = (formData) => {
    handleClose14();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_3wz4mwq",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail28 = (formData) => {
    handleClose14();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_xqewla2",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  const [show12, setShow12] = useState(false);
  const [show13, setShow13] = useState(false);
  const [show14, setShow14] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail2(formData);
    } else if (city === "Рівне") {
      sendEmail13(formData);
    }
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail1(formData);
    } else if (city === "Рівне") {
      sendEmail14(formData);
    }
  };
  const handleSubmit3 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail3(formData);
    } else if (city === "Рівне") {
      sendEmail15(formData);
    }
  };
  const handleSubmit4 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail4(formData);
    } else if (city === "Рівне") {
      sendEmail16(formData);
    }
  };
  const handleSubmit5 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail5(formData);
    } else if (city === "Рівне") {
      sendEmail17(formData);
    }
  };
  const handleSubmit6 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail6(formData);
    } else if (city === "Рівне") {
      sendEmail18(formData);
    }
  };
  const handleSubmit7 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail7(formData);
    } else if (city === "Рівне") {
      sendEmail19(formData);
    }
  };
  const handleSubmit8 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail8(formData);
    } else if (city === "Рівне") {
      sendEmail20(formData);
    }
  };
  const handleSubmit9 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail9(formData);
    } else if (city === "Рівне") {
      sendEmail21(formData);
    }
  };
  const handleSubmit10 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail10(formData);
    } else if (city === "Рівне") {
      sendEmail22(formData);
    }
  };
  const handleSubmit11 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail11(formData);
    } else if (city === "Рівне") {
      sendEmail23(formData);
    }
  };
  const handleSubmit12 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail12(formData);
    } else if (city === "Рівне") {
      sendEmail24(formData);
    }
  };
  const handleSubmit13 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail25(formData);
    } else if (city === "Рівне") {
      sendEmail26(formData);
    }
  };
  const handleSubmit14 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail27(formData);
    } else if (city === "Рівне") {
      sendEmail28(formData);
    }
  };

  return (
    <>
      <div style={{ position: "absolute", left: "0", bottom: "80px" }}>
        <ButtonOnline />
      </div>
      <Container fluid className="container">
        <p className="titleNameCourses mt-3 mb-3 pb-2 border-bottom">
          Наші курси
        </p>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText" alt="Logo" /> &nbsp;
            <p className="nameCourse1">Курс «Маленькі дослідники» 3-4 роки</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={LittleExpCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина розвине навички дрібної моторики,
              креативності та комунікабельності. Навчиться розрізняти кольори,
              та форми. Навчиться рахувати та додатково потренує свою пам’ять.
              Чітко формулюватиме свою думку та закріпить такі поняття як
              «однаковий», «різний» та «моє», «чуже».
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 7 дітей.
              <br />
              &#9679; Тривалість заняття: 50 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <p>
              <Link to="/little-explorers" className="LinkForCoursePageText">
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow2}
            >
              Записатися
            </button>
            <Modal
              show={show2}
              onHide={handleClose2}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Маленькі дослідники» 3-4 роки
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit1}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCourse1 ">
              Курс «LEGO: конструюй та вивчай» 4-5,5 років
            </p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={LegoLearn} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина поглибить знання про навколишнє середовище.
              Крім цього навчиться самостійно за інструкцією знаходити
              закономірність розташування детальок LEGO, порівнюючи свою модель
              та модель наведену в інструкції. Покращить навички дрібної
              моторики, розвине уяву та удосконалить навички спілкування з
              однолітками.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 7 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <p>
              <Link
                to="/lego-build-and-learn-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow13}
            >
              Записатися
            </button>
            <Modal
              show={show13}
              onHide={handleClose13}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «LEGO: конструюй та вивчай» 4-5,5 років
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit13}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose13}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Юні програмісти» 5-6 років</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={ProgrammersCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина засвоїть базові поняття та принципи
              програмування, покращить логічне мислення та вміння
              концентруватися на роботі, навчиться самостійно знаходити рішення
              для виконання поставленої задачі. Крім цього покращить аналітичні
              та творчі здібності, розвине уяву і фантазію.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 10 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/skilled-programmers-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow3}
            >
              Записатися
            </button>
            <Modal
              show={show3}
              onHide={handleClose3}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Юні програмісти» 5-6 років
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit3}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Механіка» 5,5-7 років</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={Mechanic} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина вивчить основні прості механізми та засвоїть
              принцип їх роботи, а також отримає знання для подальшого вивчення
              фізики, інженерії та робототехніки.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 10 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>

            <p>
              {/* Добавляем ссылку на страницу LittleExplorersPage */}
              <Link to="/mechanic-page" className="LinkForCoursePageText">
                Детальніше про курс
              </Link>
            </p>

            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow14}
            >
              Записатися
            </button>
            <Modal
              show={show14}
              onHide={handleClose14}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Механіка» 5,5-7 років
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit14}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose14}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        {/* <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Підготовка до школи» 5-6 років</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={PrepairSchoolCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Під час уроків діти вчаться основ математики, а саме розвивають
              свої навички рахунку та виконують цікаві завдання з додавання та
              віднімання. Крім того, вони вивчають навички читання, засвоюють
              ідеї про час, простір і форми, користуючись захоплюючими іграми. У
              процесі занять діти також розвивають свою кмітливість, поповнюють
              словниковий запас та навчаються виражати свої думки й
              обґрунтовувати їх.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 10 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/preparation-for-school-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow1}
            >
              Записатися
            </button>
            <Modal
              show={show1}
              onHide={handleClose1}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Підготовка до школи» 5-6 років{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit2}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row> */}
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Робототехніка WeDo» 1-2 клас</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={WedoCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина засвоїть основні поняття і принципи
              робототехніки та програмування. Навчиться працювати в команді,
              розвине просторове мислення, уяву, креативність та логіку. Крім
              цього створить своїх перших роботів та запрограмує їх.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 10 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <p>
              <Link to="/wedo-robotics-page" className="LinkForCoursePageText">
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow6}
            >
              Записатися
            </button>
            <Modal
              show={show6}
              onHide={handleClose6}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Робототехніка WeDo» 1-2 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit6}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose6}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">
              Курс «Програмування в дії» 1-2 клас
            </p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={MathematicsProgCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина розвине логічне та критичне мислення, увагу,
              пам’ять, навчиться створювати ігри та анімації відповідно до
              поставленого завдання, а також розв’язувати проблеми, які можуть
              під час цього виникати. У дитини сформується інтерес до
              інформатики та технологій, а також покращаться оцінки з математики
              у школі.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/mathematics-in-programming-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow4}
            >
              Записатися
            </button>
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Програмування в дії» 1-2 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit4}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Основи програмування» 2-3 клас</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={FundOfProgCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу діти засвоять основні поняття та принципи
              програмування, такі як змінні, цикли, умови, події, функції.
              Набуваючи практичних навичок програмування діти будуть розвивати
              логічне мислення, увагу та пам’ять. Дитина отримає задоволення від
              творчого процесу програмування і побачить свої результати в дії.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 40 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/fundamentals-of-programming-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow5}
            >
              Записатися
            </button>
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Основи програмування» 2-3 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit5}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Minecraft» 3-4 клас</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={MinecraftCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу діти зможуть розвивати свої навички в
              галузі математики, фізики, геометрії, алгоритмів та логіки.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link to="/minecraft-page" className="LinkForCoursePageText">
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow9}
            >
              Записатися
            </button>
            <Modal
              show={show9}
              onHide={handleClose9}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Minecraft» 3-4 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit9}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose9}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>

        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «Roblox» 4-5 клас</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={RobloxCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу дитина навчиться реалізовувати різні
              функції та ефекти на мові LUA, створювати власні 3D моделі для
              розробки ігор та анімувати власних персонажів, засвоїть основи
              гейм-дизайну та вебдизайну.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link to="/roblox-page" className="LinkForCoursePageText">
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow8}
            >
              Записатися
            </button>
            <Modal
              show={show8}
              onHide={handleClose8}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Roblox» 4-5 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit8}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose8}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">
              Курс «Робототехніка Mindstorms» 5-7 клас
            </p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={MindStormCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу дитина навчиться конструювати своїх
              власних роботів з LEGO та програмувати їх, навчиться
              використовувати різні команди, умови, цикли, події та функції, щоб
              зробити своїх роботів рухливими та здатними реагувати на
              навколишнє середовище.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 2 год.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <p>
              <Link
                to="/robotics-mindstorm-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow7}
            >
              Записатися
            </button>
            <Modal
              show={show7}
              onHide={handleClose7}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Робототехніка Mindstorms» 5-7 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit7}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose7}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>

        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">Курс «WEB програмування» 5-9 клас</p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={WebCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу учень ціннісні навички програмування, які
              стануть їй в пригоді в навчанні, роботі та житті, розвине творчий
              потенціал, фантазію та уяву, а також навчиться аналізувати
              інформацію, структурувати дані та створювати алгоритми. Учень
              освоїть технології, що використовуються у провідних IT-компаніях
              світу, підвищить самооцінку, отримає мотивацію та впевненість у
              своїх силах, навчиться демонструвати свої досягнення, знайде нових
              друзів та однодумців працюючи в команді та обмінюючись досвідом.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 2 год.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/web-programming-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow10}
            >
              Записатися
            </button>
            <Modal
              show={show10}
              onHide={handleClose10}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «WEB програмування» 5-9 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit10}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose10}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">
              Курс «Група продовженого дня» 6-13 років
            </p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={GroupCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Група продовженого дня сприяє гармонійному розвитку дитини, формує
              у неї пізнавальний інтерес, творчий потенціал, моральні якості,
              здоровий спосіб життя. Після відвідування групи продовженого дня
              дитина досягне кращих результатів у навчанні, збагачуючи свій
              кругозір, розширить свої можливості та перспективи.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 2 год.
              <br />
              &#9679; Кількість занять на тиждень: кожен день.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <p>
              <Link
                to="/extended-day-group-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow11}
            >
              Записатися
            </button>
            <Modal
              show={show11}
              onHide={handleClose11}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Група продовженого дня» 6-13 років
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit11}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose11}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        {/* <Row className="img2 mx-auto justify-content-center coursesBox">
          <div className="logoTextContainer">
            <img src={OnlyLogo} className="logoBeforeText"></img> &nbsp;
            <p className="nameCourse1 ">
              Курс «Підготовка до Національного мультипредметного тесту» 10-11
              клас
            </p>
          </div>
          <Col xs={12} md={6} className="img2Courses">
            <Image src={NMTCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCourses">
            <p>
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу ви будете готові до національного
              мультимедійного тесту та зможете показати високі результати. Ви
              також отримаєте гарну можливість вступити до ВНЗ на бюджетну форму
              навчання, а також навчитесь вирішувати типові та складні завдання,
              розвинете швидкість і точність, впоратися зі стресом та часом.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 6 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 2 рази на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <p>
              <Link
                to="/preparation-for-nmt-page"
                className="LinkForCoursePageText"
              >
                Детальніше про курс
              </Link>
            </p>
            <button
              type="button"
              className="btn btn-primary mb-5"
              onClick={handleShow12}
            >
              Записатися
            </button>
            <Modal
              show={show12}
              onHide={handleClose12}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal titleModalNMT">
                  Курс «Підготовка до Національного мультипредметного тесту»
                  10-11 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit12}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose12}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row> */}
      </Container>
      <Footer />
    </>
  );
};
export default Courses;
