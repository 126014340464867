import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import RobloxCourses from "../assets/RobloxCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const RobloxPage = () => {
  const [show8, setShow8] = useState(false);
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // Функция для отправки email
  const sendEmail8 = (formData) => {
    handleClose8();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_moy0i7j",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail20 = (formData) => {
    handleClose8();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_gu3jxbx",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  // Обработчик отправки формы
  const handleSubmit8 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail8(formData);
    } else if (city === "Рівне") {
      sendEmail20(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCoursePage">Курс «Roblox» 4-5 клас</p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={RobloxCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Ігрова індустрія постійно розвивається, особливо після появи
                мобільних ігор та віртуальної реальності. Це відкриває багато
                нових можливостей для тих, хто цікавиться програмуванням,
                дизайном чи створенням анімацій. Тож, якщо дитина почала
                захоплюватися іграми або цікавиться деталями процесу їх розробки
                - настав чудовий час, щоб почати вивчати ці навички. <br/>
                Курс “Roblox” надає можливість опанувати 3 основні напрямки: 3D
                моделювання, програмування та GameDesing. На курсі учні
                дізнаються основні елементи програмування на мові LUA в Roblox
                Studio, що значно полегшить вивчення будь-яких інших мов.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу дитина навчиться реалізовувати різні
              функції та ефекти на мові LUA, створювати власні 3D моделі для
              розробки ігор та анімувати власних персонажів, засвоїть основи
              гейм-дизайну та вебдизайну.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 8 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow8}
            >
              Записатися
            </button>
            <Modal
              show={show8}
              onHide={handleClose8}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Roblox» 4-5 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit8}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose8}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default RobloxPage;
