import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer";
import '../styles/ScheduleFooter.css';
import WebSchedule1 from "../assets/WebSchedule1.avif";
import WebSchedule2 from "../assets/WebSchedule2.jpg";
import WebSchedule3 from "../assets/WebSchedule3.jpg";

export default class WebProgramming extends Component {
    render() {
        return (
            <>
                 <div className="container">
                    <div className="w-95 w-md-75 w-lg-60 w-xl-55 mx-auto mb-6 text-center">
                        <h2 className="display-18 display-md-16 display-lg-14 mb-0 mt-4" style={{ fontWeight: '600', color: '#3CA3DC'}}>WEB програмування (5-9 клас)</h2>
                    </div>
                    <div className="row containerSchedule">
                        <div className="col-md-12">
                            <div className="schedule-table">
                                <table className="table bg-white mt-5">
                                    <tbody>
                                        <tr>
                                            <td className="day">Понеділок</td>
                                            <td className="day">Вівторок</td>
                                            <td className="day">Середа</td>
                                            <td className="day">Четвер</td>
                                            <td className="day">П'ятниця</td>
                                            <td className="day">Субота</td>
                                            <td className="day">Неділя</td>
                                        </tr>
                                        <tr>
                                            <td className="active">
                                            </td>
                                            <td className="active">
                                            </td>
                                            <td className="active">
                                                <p style={{ fontSize: '18px', fontWeight: '600' }}>17:00 - 19:00</p>
                                                <p style={{ fontSize: '18px', fontWeight: '600' }}>Кабінет №1</p>
                                                <div className="hover">
                                                    <p style={{ fontSize: '18px', fontWeight: '600' }} className="mt-2">17:00 - 19:00</p>
                                                    <p style={{ fontSize: '18px', fontWeight: '600' }}>Кабінет №1</p>
                                                </div>
                                            </td>
                                            <td className="active">
                                            </td>
                                            <td className="active">
                                                <p style={{ fontSize: '18px', fontWeight: '600' }}>18:20 - 20:20</p>
                                                <p style={{ fontSize: '18px', fontWeight: '600' }}>Кабінет №1</p>
                                                <div className="hover">
                                                    <p style={{ fontSize: '18px', fontWeight: '600' }} className="mt-2">18:20 - 20:20</p>
                                                    <p style={{ fontSize: '18px', fontWeight: '600' }}>Кабінет №1</p>
                                                </div>
                                            </td>
                                            <td className="active">
                                            </td>
                                            <td className="active">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Container fluid className="container">
                    <div className="imagesScheduleKinder">
                        <img className="imgScheduleKinder1" src={WebSchedule1}></img>
                        <img className="imgScheduleKinder2" src={WebSchedule2}></img>
                        <img className="imgScheduleKinder3" src={WebSchedule3}></img>
                    </div>
                </Container>
                <Footer />
            </>
        )
    }
}