import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import WedoCourses from "../assets/WedoCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const WeDoRoboticsPage = () => {
  const [show6, setShow6] = useState(false);
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // Функция для отправки email
  const sendEmail6 = (formData) => {
    handleClose6();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_smemd6s",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail18 = (formData) => {
    handleClose6();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_dx6hg3n",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  // Обработчик отправки формы
  const handleSubmit6 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail6(formData);
    } else if (city === "Рівне") {
      sendEmail18(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCoursePage">Курс «Робототехніка WeDo» 1-2 клас</p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={WedoCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Після появи механізованої техніки люди все більше почали
                задумуватися над тим як же автоматизувати більшість механічних
                процесів. З ерою автоматизації й народилася достатньо молода й
                доволі важлива наука під назвою робототехніка, яка поєднує в
                собі механіку, електроніку і програмування. <br/>
                Курс «Робототехніка WeDo» - практичний шлях для учнів 1-2 класів у світ технологій
                та робототехніки. Під час цього курсу діти навчаються створювати
                своїх перших роботів використовуючи LEGO WeDo, працюють з
                датчиками та моторам. Невідмінною частиною роботи є також
                програмування, адже без нього робот так і залишиться звичайною
                статичною моделлю.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Протягом курсу дитина засвоїть основні поняття і принципи
              робототехніки та програмування. Навчиться працювати в команді,
              розвине просторове мислення, уяву, креативність та логіку. Крім
              цього створить своїх перших роботів та запрограмує їх.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 10 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 1 заняття на тиждень.
              <br />
              &#9679; Курс: Офлайн.
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow6}
            >
              Записатися
            </button>
            <Modal
              show={show6}
              onHide={handleClose6}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Робототехніка WeDo» 1-2 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit6}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose6}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default WeDoRoboticsPage;
