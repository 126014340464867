import React, { Component } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import '../styles/CarouselBox.css';
import banner1 from '../assets/banner1.png';
import banner2 from '../assets/banner2.png';
import banner3 from '../assets/banner3.png';
import banner4 from '../assets/banner4.png';
import AutumnCamp from '../assets/AutumnCamp.png';
import mobileBanner1 from '../assets/mobileBanner1.jpg';
import mobileBanner2 from '../assets/mobileBanner2.jpg';
import mobileBanner3 from '../assets/mobileBanner3.jpg';
import mobileBanner4 from '../assets/mobileBanner4.jpg';
import AutumnCampMobile from '../assets/AutumnCampMobile.png';
import ArrowMain from '../assets/ArrowMain.webp';
import SelectionOfCourses from './SelectionOfCourses';

export default class CarouselBox extends Component {
    state = {
        isMobile: window.innerWidth < 768,
    };

    componentDidMount() {
        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const windowWidth = window.innerWidth;
        const mainContainer = document.querySelector('.mainContainer');
        let scale;
        if (windowWidth > 1920) {
            scale = 1920 / windowWidth;
        } else {
            scale = 1;
        }
        if (mainContainer) {
            mainContainer.style.transform = `scale(${scale})`;
            mainContainer.style.transformOrigin = 'top center';
        }
        this.setState({ isMobile: window.innerWidth < 712 });
    }

    render() {
        const { isMobile } = this.state;
        const images = [
            { imgSrc: isMobile ? mobileBanner1 : banner1, altText: 'First slide', class: 'slide1' },
            { imgSrc: isMobile ? mobileBanner2 : banner2, altText: 'Second slide', class: 'slide2' },
            { imgSrc: isMobile ? mobileBanner3 : banner3, altText: 'Third slide', class: 'slide3' },
            { imgSrc: isMobile ? mobileBanner4 : banner4, altText: 'Fourth slide', class: 'slide4' },
            // { imgSrc: isMobile ? AutumnCampMobile : AutumnCamp, altText: 'Fifth slide', class: 'slide5' },
        ];

        return (
            <Carousel className="mainContainer">
                {images.map((item, index) => (
                    <Carousel.Item key={index}>
                        <div
                            className={`d-block w-100 position-relative ${
                                index !== 4 ? 'imgBG2' : ''
                            }`}
                            style={{
                                backgroundImage: `url(${item.imgSrc})`,
                                height: isMobile ? '680px' : '600px',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        ></div>

                        {/* Отображаем текст только для первых 4 слайдов */}
                        {index !== 4 && (
                            <Container className="position-absolute top-50 start-50 translate-middle text-center titleMain">
                                <Row>
                                    <Col xs={12} md={6} className="mb-4">
                                        <p className="carousel-title">
                                            Tech Kids Academy–<br />
                                            освіта майбутнього!
                                        </p>
                                        <p className="carousel-text">
                                            Ми створюємо світ, де діти розкривають свій потенціал і реалізують індивідуальні здібності
                                        </p>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={6}
                                        className="d-flex flex-column align-items-center justify-content-md-end"
                                    >
                                        <div className="arrowMain mb-3">
                                            <img
                                                style={{ width: '200px', marginLeft: '100px' }}
                                                src={ArrowMain}
                                                alt="Arrow GIF"
                                            />
                                        </div>
                                        <SelectionOfCourses />
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }
}
