import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../styles/StyleForCoursesPages.css";
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import NMTCourses from "../assets/NMTCourses.jpg";
import emailjs from "emailjs-com"; // Предполагаем, что вы используете emailjs для отправки писем

const PreparationForNMTPage = () => {
  const [show12, setShow12] = useState(false);
  const form = useRef(); // Используем useRef для ссылки на форму

  // Функции для открытия и закрытия модального окна
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // Функция для отправки email
  const sendEmail12 = (formData) => {
    handleClose12();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_abmvww9",
      data,
      "4upStKj7ORtxgJNce"
    );
  };
  const sendEmail24 = (formData) => {
    handleClose12();
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const age = formData.get("age");
    const city = formData.get("city");
    const data = {
      name: name,
      email: email,
      phone: phone,
      age: age,
      city: city,
    };
    emailjs.send(
      "service_5gqx2v7",
      "template_xove8em",
      data,
      "4upStKj7ORtxgJNce"
    );
  };

  const handleSubmit12 = (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const city = formData.get("city");
    if (city === "Київ") {
      sendEmail12(formData);
    } else if (city === "Рівне") {
      sendEmail24(formData);
    }
  };

  return (
    <>
      <Container>
        <Row className="img2 mx-auto justify-content-center coursesBoxPage">
          <div className="logoTextContainerPage">
            <img src={OnlyLogo} className="logoBeforeText"></img>&nbsp;
            <p className="nameCoursePage">
              Курс «Підготовка до Національного мультипредметного тесту» 10-11
              клас
            </p>
          </div>
          <Col xs={12} md={6} className="img2CoursesPage">
            <Image src={NMTCourses} fluid />
          </Col>
          <Col xs={12} md={6} className="textInfoCoursesPage">
            <p>
              <span className="successTitleCourses">Опис курсу:</span>
              <p>
                Отримання вищої освіти - це не лише можливість отримати
                професію, великий крок в доросле життя. Цей крок може відіграти
                значну роль в житті будь-якої людини. Вона дає впевненість у
                тому, що людин зможе досягти своїх цілей і бути готовою до
                будь-яких викликів, які постануть перед ним. <br/>
                Навчання в виші надає можливість отримати глибші знання в обраній сфері та не
                тільки допоможе стати справжнім професіоналом своєї справи, але
                й дасть змогу краще розуміти, як працює світ навколо. Для
                реалізації задуманих планів дуже важливим етапом є успішне
                складання екзаменаційних шкільних іспитів. З цим допоможе курс
                «Підготовка до Національного мультимедійного тесту». <br/>
                Підготовка до випускних шкільних екзаменів в Tech Kids Academy -
                спеціалізована програма, яка надає старшокласникам комплексну
                підготовку до успішного складання випускних екзаменів у шкоді.
                Програма містить огляд основних тем та питань, що входять до
                випускних іспитів.
              </p>
              <br />
              <span className="successTitleCourses">
                Досягаємо результатів:
              </span>
              <br />
              Після проходження курсу ви будете готові до національного
              мультимедійного тесту та зможете показати високі результати. Ви
              також отримаєте гарну можливість вступити до ВНЗ на бюджетну форму
              навчання, а також навчитесь вирішувати типові та складні завдання,
              розвинете швидкість і точність, впоратися зі стресом та часом.
              <br />
              <br />
              &#9679; Кількість дітей в групі: до 6 дітей.
              <br />
              &#9679; Тривалість заняття: 1 год 30 хв.
              <br />
              &#9679; Кількість занять на тиждень: 2 рази на тиждень.
              <br />
              &#9679; Курс: Онлайн та Офлайн.
            </p>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleShow12}
            >
              Записатися
            </button>
            <Modal
              show={show12}
              onHide={handleClose12}
              backdrop={false}
              className="modalFormEmail"
            >
              <Modal.Header closeButton>
                <Modal.Title className="titleModal">
                  Курс «Підготовка до Національного мультипредметного тесту»
                  10-11 клас
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={form} onSubmit={handleSubmit12}>
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Label className="textModal">
                      Ім'я та прізвище:
                    </Form.Label>
                  </Form.Group>
                  <textarea
                    className="textAreaForm"
                    name="name"
                    required
                    title="Будь ласка, введіть ім'я та прізвище"
                  />
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label className="textModal mt-1">
                      Електронна адреса:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    type="email"
                    name="email"
                    required
                    plaintext
                    title="Будь ласка, введіть електронну адресу"
                  />
                  <Form.Group className="" controlId="formBasicPhone">
                    <Form.Label className="textModal mt-2">
                      Номер телефону:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm "
                    type="tel"
                    name="phone"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть номер телефону"
                  />
                  <Form.Group className="" controlId="formBasicAge">
                    <Form.Label className="textModal mt-2">
                      Вік дитини:
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    className="textAreaForm"
                    name="age"
                    pattern="[0-9]*"
                    required
                    plaintext
                    title="Будь ласка, введіть вік дитини"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label className="textModal mt-1 ">
                      Ваше місто:
                    </Form.Label>
                    <select
                      className="textAreaForm"
                      name="city"
                      required
                      title="Будь ласка, оберіть місто"
                    >
                      <option value="">Оберіть місто</option>
                      <option value="Київ">Київ</option>
                      <option value="Рівне">Рівне</option>
                    </select>
                  </Form.Group>
                  <div className="mb-3">
                    <label htmlFor="dataProcessingAgreement">
                      <input
                        type="checkbox"
                        id="dataProcessingAgreement"
                        required
                      />
                      &nbsp;Натискаючи кнопку, я даю згоду на обробку
                      персональних даних.
                    </label>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose12}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="primary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PreparationForNMTPage;
