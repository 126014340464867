import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import '../styles/ScrollToTopButton.css';
import { FaCircleArrowUp } from "react-icons/fa6";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const [bottomDistance, setBottomDistance] = useState(10); // Расстояние от нижнего края экрана

  // Функция для прокрутки вверх
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Показывать кнопку только при скролле вниз и управлять расстоянием от футера
  useEffect(() => {
    const handleScroll = () => {
      const footerHeightDesktop = 160;
      const footerHeightMobile = 450;
      const footerHeightMobileSmall = 450;
      const scrolledFromTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const scrollBottom = docHeight - (windowHeight + scrolledFromTop);

      let footerHeight = footerHeightDesktop;
      if (window.innerWidth <= 767) {
        footerHeight = footerHeightMobile;
        if (window.innerWidth <= 400) {
          footerHeight = footerHeightMobileSmall;
        }
      }

      if (scrollBottom <= footerHeight) {
        setBottomDistance(footerHeight - scrollBottom + 10);
      } else {
        setBottomDistance(10);
      }

      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Button
        onClick={scrollToTop}
        className={`scroll-to-top ${showButton ? 'show' : ''}`}
        style={{ bottom: `${bottomDistance}px` }} // Динамическое управление bottom
        variant="primary"
      >
        <FaCircleArrowUp />
      </Button>
    </>
  );
};

export default ScrollToTopButton;
